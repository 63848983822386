import { getDatabase } from 'firebase/database';
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";


const firebaseConfig = {
    apiKey: "AIzaSyAEw--80DXjWLMen1zma71sMsjwzDfZX9Y",
    authDomain: "frogdream-stocks2.firebaseapp.com",
    databaseURL: "https://frogdream-stocks2.firebaseio.com",
    projectId: "frogdream-stocks5",
    storageBucket: "frogdream-stocks3",
    messagingSenderId: "1037116817207",
    appId: "1:1037116817207:web:9772a577a07570671664b2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();

const auth = getAuth(app);
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    console.log("%s (%s) is logged in", user.displayName, user.email);
  } else {
    // No one signed in - use Google Auth popup
    signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            // const credential = GoogleAuthProvider.credentialFromResult(result);
            // const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            console.log("User %s (%s) logged in", user.displayName, user.email);
            window.location.reload(false)
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.email;
            // The AuthCredential type that was used.
            // const credential = GoogleAuthProvider.credentialFromError(error);
            console.error("User %s tried to login but failed: error %d (%s)", email, errorCode, errorMessage);
        });
  }
});

const db = getDatabase();

export default db;
