import { ref, onValue } from 'firebase/database';
import db from './firebase';

export default class PositionsService {
    constructor(dispatch) {
        this.dispatch = dispatch;
        this.rowData = [];
    }

    rowToHash(row) {
        // console.log("Converting row " + JSON.stringify(row));
        var quantity = parseInt(row.Quantity);
        var price = parseFloat(row.Price);
        var originalPrice = parseFloat(row.OriginalPrice);
        var change = parseFloat(row.Change);
        var percentGain = (originalPrice === 0) ? 1.0 : (price - originalPrice) / originalPrice;
        return {
            id: row.id,
            quantity: quantity,
            price: price,
            originalPrice: originalPrice,
            percentGain: percentGain,
            capitalGain: quantity * (price - originalPrice),
            change: change,
            dayValueChange: quantity * change,
            value: quantity * price,
        };
    };

    start() {
        console.log("Started PositionsService");
        const valuesRef = ref(db, '/1Ywp7rel8YqE2jdCqfTiDpuOGEQC8C04FsibFIPChyiM/values');

        onValue(valuesRef, (snapshot) => {
            // var keys = Object.keys(snapshot.val());
            // console.log("onValue positions: %s", JSON.stringify(keys));
            console.log("snapshot rows: %s", Object.keys(snapshot.val()).length);
            try {
                var newFooter = { id: 'Total', capitalGain: 0.0, value: 0.0, dayValueChange: 0.0 };
                var newFooterData = [ newFooter ];
                var newRows = [];
                Object.keys(snapshot.val()).forEach((asxCode) => {
                    var newRow = this.rowToHash(snapshot.val()[asxCode]);
                    if (newRow != null && newRow.quantity > 0) {
                        newRows.push(newRow);
                        newFooter.capitalGain += newRow.capitalGain;
                        newFooter.value += newRow.value;
                        newFooter.dayValueChange += newRow.dayValueChange;
                    }
                });
                newRows.forEach((newRow) => {
                    if (newRow != null && newRow.quantity > 0) {
                        newRow.percentValue = newRow.value / newFooter.value;
                    }
                });
                console.log("onValue newRows: %s", Object.keys(newRows).length);
                this.dispatch({
                    type: 'POSITIONS_UPDATED',
                    rowData: newRows.slice(0),
                    footerData: newFooterData.slice(0)
                });
            } catch (error) {
                console.error(error);
            }
        });
    }
}
