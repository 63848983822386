import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
import App from "./App";
import PositionsService from "./PositionsService";
import StocksService from "./StocksService";
import reportWebVitals from './reportWebVitals';

// a simple reducer
let gridDataReducer = (state = { 
        rowData: [], 
        footerData: [], 
        stocksData: []
    }, action) => {
    switch (action.type) {
        case 'POSITIONS_UPDATED':
            // console.log("action = " + JSON.stringify(action));
            console.log("action: positions rows = " + action.rowData.length);
            console.log("action: positions state rows = " + state.rowData.length);
            return {
                ...state,
                rowData: action.rowData,
                footerData: action.footerData,
            };
        case 'STOCKS_UPDATED':
            console.log("action: stocks rows = " + action.rowData.length);
            return {
                ...state,
                stocksData: action.rowData
            };
        default:
            return state;
    }
};
// create the Redux store
let store = createStore(gridDataReducer);
// instantiate our Service and pass in the Redux store dispatch method
let positionsService = new PositionsService(store.dispatch);
let stocksService = new StocksService(store.dispatch);

// wait for the dom to be ready, then render our application
document.addEventListener('DOMContentLoaded', () => {
    render(
        <React.StrictMode>
            <Provider store={store}>
                <App/>
            </Provider>
        </React.StrictMode>,
        document.getElementById('root')
    );
    positionsService.start();
    stocksService.start();
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
