import { ref, onValue } from 'firebase/database';
import db from './firebase';

export default class StocksService {
    constructor(dispatch) {
        this.dispatch = dispatch;
        this.rowData = [];
    }

    rowToHash(row) {
        return {
            id: row.asxCode,
            companyName: row.companyName,
            lvr: parseFloat(row.lvr) * 100.0,
            retrievePrice: String(row.retrievePrice) === 'true',
            symbol: row.symbol,
            lastPrice: -99.9,
        };
    };

    start() {
        console.log("Started StocksService");
        const stocksRef = ref(db, '/stocks');
        console.log("stocksRef = %s", stocksRef);
        onValue(stocksRef, (snapshot) => {
            console.log("onValue stocksRef")
            try {
                // console.log("Stocks: %s", JSON.stringify(snapshot.val()));
                var newRows = [];
                Object.keys(snapshot.val()).forEach((asxCode) => {
                    // console.log("asxCode = %s", asxCode);
                    var newRow = this.rowToHash(snapshot.val()[asxCode]);
                    newRows.push(newRow);
                });
                // console.log("newRows: %s", JSON.stringify(newRows));
                this.dispatch({
                    type: 'STOCKS_UPDATED',
                    rowData: newRows.slice(0)
                });
            } catch (error) {
                console.error(error);
            }
        });
    }
}