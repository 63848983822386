import * as React from "react";
import _ from "lodash";

import { AgGridReact } from 'ag-grid-react';

import { connect } from 'react-redux'

import GridComponents from "./Components";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import "./index.scss";

const frameworkComponents = {
    simpleEditor: GridComponents.SimpleEditor,
}

function priceFormatter(params) {
    var priceStr = '';
    if (params.value != null && params.value !== 'n/a') {
        priceStr = Intl.NumberFormat('en-AU', {
            style: 'currency',
            currency: 'AUD',
            minimumFractionDigits: 3,
            maximumFractionDigits: 3
        }).format(params.value);
    } else {
        priceStr = params.value;
    }
    return priceStr;
}

function percentFormatter(params) {
    if (params.value != null) {
        var currStr = _.round(params.value, 0).toFixed(0);
        return currStr + '%';
    } else {
        return '';
    }
}



class Stocks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: this.createColumnDefs(),
            stocksData: [],
            cellFlashDelay: 2500,
            cellFadeDelay: 2500,
        }
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.columnApi.autoSizeColumns(Object.keys(this.state.columnDefs));
    }

    createColumnDefs() {
        return [
            { headerName: 'ASX Code', field: 'id' },
            { headerName: 'Company Name', field: 'companyName' },
            { headerName: 'LVR', field: 'lvr', valueFormatter: percentFormatter, cellClass: 'centred', editable: true },
            {
                headerName: 'Retrieve Price',
                field: 'retrievePrice',
                cellClass: 'centred',
                editable: true,
                cellEditor: "simpleEditor",
                cellRenderer: params => {
                    return `<input type='checkbox' ${params.value ? 'checked' : ''} />`;
                }
            },
            { headerName: 'Latest Price', field: 'latestPrice', valueFormatter: priceFormatter, cellClass: 'number-cell' },
        ];
    }

    render() {
        return (
            <div>
                <div className="ag-custom-loading-cell" style={{ paddingLeft: '10px', lineHeight: '25px' }}>
                    <i className="fas fa-spinner fa-pulse"></i> <span> {this.props.loadingMessage}</span>
                </div>
                <div style={{ width: '100%', height: '100%' }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        cellFlashDelay={this.state.cellFlashDelay}
                        cellFadeDelay={this.state.cellFadeDelay}
                        rowData={this.props.stocksData}
                        animateRows={true}
                        frameworkComponents={frameworkComponents}
                        editType="fullRow"
                        suppressClickEdit
                        immutableData
                        paginationPageSize={50}
                        pagination={true}
                        getRowNodeId={(data) => data.id}
                        domLayout={'autoHeight'}
                        onGridReady={this.onGridReady.bind(this)}
                    />
                </div>
            </div>
        );
    }
}


export default connect(
    (state) => {
        return {
            stocksData: state.stocksData,
        }
    }
)(Stocks);