import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';

function NavigationBar() {
    return (
        <Navbar bg="primary" variant="dark">
                <Nav className="me-auto">
                    <Nav.Link href="/">Positions</Nav.Link>
                    <Nav.Link href="#stocks">Stocks</Nav.Link>
                </Nav>
        </Navbar>
    )
}

export default NavigationBar;