import * as React from "react";
import Timestamp from 'react-timestamp';

import { AgGridReact } from 'ag-grid-react';

import { connect } from 'react-redux'

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import "./index.scss";



const colourCellClassRules = {
    'number-cell-decrease': function(params) {
        var value = Number(params.value);
        /*        if (params.colDef.field === 'dayValueChange') {
                    console.log("decrease: column=" + params.colDef.field + ", row=" + params.rowIndex + ", value=" + value + ", result=" + (value < 0));
                }*/
        return value < 0;
    },
    'number-cell-increase': function(params) {
        var value = Number(params.value);
        /*        if (params.colDef.field === 'dayValueChange') {
                  console.log("increase: column=" + params.colDef.field + ", row=" + params.rowIndex + ", value=" + value + ", result=" + (value >= 0));
                }*/
        return value >= 0;
    }
}

function currencyFormatter(params) {
    var currStr = '';
    if (params.value != null) {
        currStr = Intl.NumberFormat('en-AU', {
            style: 'currency',
            currency: 'AUD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(params.value);
    }
    return currStr;
}

function priceFormatter(params) {
    var priceStr = '';
    if (params.value != null) {
        priceStr = Intl.NumberFormat('en-AU', {
            style: 'currency',
            currency: 'AUD',
            minimumFractionDigits: 3,
            maximumFractionDigits: 3
        }).format(params.value);
    }
    return priceStr;
}

function percentFormatter(params) {
    if (params.value != null) {
        var currStr = Number(params.value).toLocaleString(undefined, {
            style: 'percent', minimumFractionDigits: 1,
            maximumFractionDigits: 1
        });
        return currStr;
    } else {
        return '';
    }
}

class Positions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: this.createColumnDefs(),
            footerData: [{ id: 'Total', capitalGain: 0.0, value: 0.0, dayValueChange: 0.0 }],
            tableOptions: { alignedGrids: [] },
            footerOptions: { alignedGrids: [] },
            rowData: [],
            cellFlashDelay: 2500,
            cellFadeDelay: 2500,
        }
        this.state.tableOptions.alignedGrids.push(this.state.footerOptions);
        this.state.footerOptions.alignedGrids.push(this.state.tableOptions);
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.columnApi.autoSizeColumns(Object.keys(this.state.columnDefs));
    }

    createColumnDefs() {
        return [
            { headerName: 'ASX Code', field: 'id', sortable: true },
            { headerName: 'Quantity', field: 'quantity', cellClass: 'number-cell', enableCellChangeFlash: true },
            { headerName: 'Original Price', field: 'originalPrice', valueFormatter: priceFormatter, cellClass: 'number-cell', enableCellChangeFlash: true },
            { headerName: 'Last Price', field: 'price', valueFormatter: priceFormatter, cellClass: 'number-cell', enableCellChangeFlash: true },
            { headerName: '% Gain', field: 'percentGain', valueFormatter: percentFormatter, cellClassRules: colourCellClassRules, sortable: true, enableCellChangeFlash: true },
            { headerName: 'Capital Gain', field: 'capitalGain', valueFormatter: currencyFormatter, cellClassRules: colourCellClassRules, sortable: true, enableCellChangeFlash: true },
            { headerName: 'Day Change', field: 'change', valueFormatter: priceFormatter, cellClassRules: colourCellClassRules, sortable: true, enableCellChangeFlash: true },
            { headerName: 'Value', field: 'value', valueFormatter: currencyFormatter, cellClass: 'number-cell', sortable: true, enableCellChangeFlash: true },
            { headerName: '% Value', field: 'percentValue', valueFormatter: percentFormatter, cellClassRules: colourCellClassRules, sortable: true, enableCellChangeFlash: false },
            { headerName: 'Day Value Change', field: 'dayValueChange', valueFormatter: currencyFormatter, cellClassRules: colourCellClassRules, sortable: true, enableCellChangeFlash: true },
        ];
    }

    render() {
        return (
            <div>
                <div className="ag-custom-loading-cell" style={{ paddingLeft: '10px', lineHeight: '25px' }}>
                    <i className="fas fa-spinner fa-pulse"></i> <span> {this.props.loadingMessage}</span>
                </div>
                <div style={{ width: '100%' }}>
                    <AgGridReact
                        gridOptions={this.state.tableOptions}
                        columnDefs={this.state.columnDefs}
                        cellFlashDelay={this.state.cellFlashDelay}
                        cellFadeDelay={this.state.cellFadeDelay}
                        rowData={this.props.rowData}
                        animateRows={true}
                        immutableData
                        getRowNodeId={(data) => data.id}
                        domLayout={'autoHeight'}
                        onGridReady={this.onGridReady.bind(this)}
                    />
                </div>
                <div style={{ width: '100%' }}>
                    <AgGridReact
                        gridOptions={this.state.footerOptions}
                        columnDefs={this.state.columnDefs}
                        cellFlashDelay={this.state.cellFlashDelay}
                        cellFadeDelay={this.state.cellFadeDelay}
                        rowData={this.props.footerData}
                        domLayout={'autoHeight'}
                        headerHeight="0"
                        immutableData
                        getRowNodeId={(data) => data.id}
                        rowStyle={{ fontWeight: 'bold' }}
                    />
                </div>
                <div>Updated at <Timestamp time={this.state.updated} format='full' includeDay /></div>
            </div>
        );
    }
}


export default connect(
    (state) => {
        return {
            rowData: state.rowData,
            footerData: state.footerData,
        }
    }
)(Positions);